import axios from 'axios';
import { Buffer } from 'buffer';

const API_URL =
	process.env.NODE_ENV === 'production'
		? 'https://api6.chiefsocials.com'
		: 'http://localhost:5001/chiefsocials/us-central1/api';

function decodeTokenPayload(token) {
	// eslint-disable-next-line no-console
	console.log({ token, where: 'decodeTokenPayload' });
	if (!token || token === 'undefined' || token.length < 20) return null;
	const tokenPayload = Buffer.from(token.split('.')[1], 'base64').toString('utf8');
	return JSON.parse(tokenPayload);
}

function manageError(errorMessage) {
	// eslint-disable-next-line no-console
	console.error(errorMessage);
	logoutUser();
}

function refreshAccessToken() {
	// eslint-disable-next-line no-async-promise-executor
	return new Promise(async (resolve, reject) => {
		try {
			// eslint-disable-next-line no-console
			console.log('refreshAccessToken');
			const refreshToken = localStorage.getItem('refreshToken');
			const userId = localStorage.getItem('userId');

			if (!refreshToken || !userId) throw new Error('Missing refresh token or user ID');

			const { data } = await axios.post(`${API_URL}/auth/refresh`, {
				refreshToken,
				userId,
			});

			if (!data) throw new Error('No data returned from refresh token');

			const { accessToken } = data;

			// Save the new access token
			localStorage.setItem('accessToken', accessToken);
			resolve(accessToken);
		} catch (error) {
			manageError(error);
			// eslint-disable-next-line no-console
			console.error(error);
			reject(error);
		}
	});
}

async function getAccessToken() {
	// eslint-disable-next-line no-async-promise-executor
	return new Promise(async (resolve, reject) => {
		try {
			let token = localStorage.getItem('accessToken');
			if (!token) {
				logoutUser();
				reject(new Error('No access token found'));
			}
			const tokenPayload = decodeTokenPayload(token);
			if (tokenPayload.exp * 1000 < Date.now()) {
				token = await refreshAccessToken();
			}
			resolve(token);
		} catch (error) {
			manageError(error);
			// eslint-disable-next-line no-console
			console.error(error);
			reject(error);
		}
	});
}

export async function isUser() {
	// eslint-disable-next-line no-async-promise-executor, consistent-return
	return new Promise(async (resolve) => {
		try {
			const token = localStorage.getItem('accessToken');

			if (!token || token.length < 20) {
				await logoutUser();
				// throw new Error('No access token found');
				// eslint-disable-next-line no-promise-executor-return
				return resolve(false);
			}

			const tokenPayload = decodeTokenPayload(token);
			const { exp } = tokenPayload;
			if (exp < Date.now() / 1000) {
				const accessToken = await refreshAccessToken();
				resolve(accessToken.length > 0);
			}

			resolve(true);
		} catch (error) {
			manageError(error);
			// eslint-disable-next-line no-console
			console.error(error);
			resolve(false);
		}
	});
}

export async function loginUser({ email, password }) {
	// eslint-disable-next-line no-async-promise-executor
	return new Promise(async (resolve, reject) => {
		try {
			const user = await axios.post(`${API_URL}/auth/login`, { email, password });

			// Get response data
			const { accessToken, userId, refreshToken, displayName } = user.data;

			// Decode token payload
			const payload = decodeTokenPayload(accessToken);

			// eslint-disable-next-line no-console
			console.log({ payload });

			// Store variables in local storage
			localStorage.setItem('accessToken', accessToken);
			localStorage.setItem('userId', userId);
			localStorage.setItem('refreshToken', refreshToken);
			localStorage.setItem('displayName', displayName);

			resolve(user.data);
		} catch (error) {
			const errorMessage = error.response.data;

			// eslint-disable-next-line no-console
			console.error(error);
			reject(errorMessage);
		}
	});
}

export async function registerUser({ email, password, displayName }) {
	// eslint-disable-next-line no-async-promise-executor
	return new Promise(async (resolve, reject) => {
		try {
			const user = await axios.post(`${API_URL}/auth/register`, {
				email,
				password,
				displayName,
			});

			// Get response data
			const { accessToken, userId, refreshToken } = user.data;

			// Decode token payload
			const payload = decodeTokenPayload(accessToken);

			// eslint-disable-next-line no-console
			console.log({ payload });

			// Store variables in local storage
			localStorage.setItem('accessToken', accessToken);
			localStorage.setItem('userId', userId);
			localStorage.setItem('refreshToken', refreshToken);
			localStorage.setItem('displayName', displayName);

			resolve(user.data);
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error(error);
			reject(error.response.data);
		}
	});
}

export async function logoutUser() {
	// eslint-disable-next-line no-async-promise-executor
	return new Promise(async (resolve) => {
		await localStorage.clear();
		// redirect to login page
		resolve();
	});
}

export async function getNotifications() {
	// eslint-disable-next-line no-async-promise-executor
	return new Promise(async (resolve, reject) => {
		try {
			const notifications = await axios.get(`${API_URL}/notifications`, {
				headers: { Authorization: `Bearer ${await getAccessToken()}` },
			});
			resolve(notifications.data);
		} catch (error) {
			manageError(error);
			// eslint-disable-next-line no-console
			console.error(error);
			reject(error);
		}
	});
}

export async function getBalance() {
	// eslint-disable-next-line no-async-promise-executor
	return new Promise(async (resolve, reject) => {
		try {
			const balance = await axios.get(`${API_URL}/user/balance`, {
				headers: { Authorization: `Bearer ${await getAccessToken()}` },
			});
			resolve(balance.data);
		} catch (error) {
			manageError(error);
			// eslint-disable-next-line no-console
			console.error(error);
			reject(error);
		}
	});
}
