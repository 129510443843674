import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// import { createUseStyles } from 'react-jss';
import Icon from '../../components/icon/Icon';
import Logo from '../../components/Logo';
// import Button from '../../components/bootstrap/Button';
// import logo from '../../assets/logos/logo.svg';
// /Users/dermot/Documents/Work/Chief Socials Limited/Website/ChiefSocials-Web/src/assets/logos/logo.svg

// const useStyles = createUseStyles({
// 	logo: {
// 		height: '100%',
// 		backgroundImage: `url(${Logo})`,
// 		backgroundPosition: 'center',
// 		backgroundRepeat: 'no-repeat',
// 		backgroundSize: 'contain',
// 	},
// });

const Brand = ({ asideStatus, setAsideStatus }) => (
	// contain brand inside aside
	<div className='brand'>
		<div className='brand-logo'>
			<h1 className='brand-title'>
				<Link to='/' aria-label='Logo' alt='Facit'>
					<Logo width='100%' />
				</Link>
			</h1>
		</div>
		<button
			type='button'
			className='btn brand-aside-toggle'
			aria-label='Toggle Aside'
			onClick={() => setAsideStatus(!asideStatus)}>
			<Icon icon='FirstPage' className='brand-aside-toggle-close' />
			<Icon icon='LastPage' className='brand-aside-toggle-open' />
		</button>
	</div>
);
Brand.propTypes = {
	asideStatus: PropTypes.bool.isRequired,
	setAsideStatus: PropTypes.func.isRequired,
};

export default Brand;
