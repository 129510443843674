import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useQuery, QueryClient, QueryClientProvider } from 'react-query';
import USERS from '../../common/data/userDummyData';
import { logoutUser, getBalance } from '../../helpers/api';
// import { demoPages } from '../../menu';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../components/bootstrap/Dropdown';
import Button from '../../components/bootstrap/Button';
// import useDarkMode from '../../hooks/useDarkMode';

export const UserAvatar = ({ srcSet, src }) => {
	return (
		<div className='user-avatar'>
			<img srcSet={srcSet} src={src} alt='Avatar' width={128} height={128} />
		</div>
	);
};
UserAvatar.propTypes = {
	src: PropTypes.string.isRequired,
	srcSet: PropTypes.string,
};
UserAvatar.defaultProps = {
	srcSet: null,
};

const queryClient = new QueryClient();
const User = ({ isUser }) => {
	const navigate = useNavigate();
	// const { darkModeStatus, setDarkModeStatus } = useDarkMode();
	const [displayName, setDisplayName] = React.useState('');

	// On Route Change, if auth status changed, update currentMenu
	useEffect(() => {
		if (isUser) {
			setDisplayName(localStorage.getItem('displayName'));
		} else {
			setDisplayName('');
		}
	}, [isUser]);

	const { data: getBalanceData } = useQuery('userBalance', getBalance);
	const [balance, setBalance] = useState('0.00');

	useEffect(() => {
		if (getBalanceData) {
			setBalance(getBalanceData.balance);
		}
	}, [getBalanceData]);

	return (
		<Dropdown>
			<DropdownToggle hasIcon={false}>
				<div className='user'>
					<UserAvatar srcSet={USERS.JOHN.srcSet} src={USERS.JOHN.src} />
					<div className='user-info'>
						<div className='user-name'>{`${displayName}`}</div>
						<div className='user-sub-title'>Balance €{balance}</div>
					</div>
				</div>
			</DropdownToggle>
			<DropdownMenu>
				{/* <DropdownItem> */}
				{/* <Button */}
				{/* icon='AccountBox' */}
				{/* onClick={() => */}
				{/* navigate( */}
				{/* `../${demoPages.appointment.subMenu.employeeID.path}/${USERS.JOHN.id}`, */}
				{/* ) */}
				{/* }> */}
				{/* Profile */}
				{/* </Button> */}
				{/* </DropdownItem> */}
				<DropdownItem>
					<Button
						icon='Settings'
						onClick={() => {
							// eslint-disable-next-line no-console
							console.log('Goto profile');
							navigate('/profile');
						}}>
						Settings
					</Button>
					{/* <Button
						icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
						onClick={() => setDarkModeStatus(!darkModeStatus)}
						aria-label='Toggle fullscreen'>
						{darkModeStatus ? 'Dark Mode' : 'Light Mode'}
					</Button> */}
				</DropdownItem>
				<DropdownItem>
					<Button
						icon='AttachMoney'
						onClick={() => {
							// eslint-disable-next-line no-console
							console.log('ADD BALANCE');
							navigate('/wallet');
						}}>
						Add Balance
					</Button>
				</DropdownItem>
				<DropdownItem isDivider />
				<DropdownItem>
					<Button
						icon='Logout'
						onClick={async () => {
							// eslint-disable-next-line no-console
							console.log('LOGOUT');

							await logoutUser();
							navigate('/');
						}}>
						Logout
					</Button>
				</DropdownItem>
			</DropdownMenu>
		</Dropdown>
	);
};

User.propTypes = {
	isUser: PropTypes.bool.isRequired,
};

// Higher order function
const hof = (WrappedComponent) => {
	// Its job is to return a react component warpping the baby component
	return () => (
		<QueryClientProvider client={queryClient}>
			<WrappedComponent />
		</QueryClientProvider>
	);
};

export default hof(User);
